<adf-login
  [copyrightText]="'application.copyright' | adfAppConfig | translate"
  providers="ECM"
  logoImageUrl="https://c19files.org/wp-content/uploads/2023/09/2023-09-10_14-00-02.png"
  successRoute="/personal-files"
  logoImageUrl="./assets/images/alfresco-logo.svg"
  backgroundImageUrl="./assets/images/Wallpaper-BG-generic.svg"
  registerLink="https://login.c19files.org"
  [showRememberMe]="false"
  [showLoginActions]="false"
>

<!-- <button (myCLick)="myClick($event)">sdf</button> -->
<!-- <div> -->

  <!-- (executeSubmit)="executeSubmit($event)" -->
  
  <!-- <div> -->
    <!-- <button (myClick)="myClick()">sdf</button> -->

    <!-- <button mat-menu-item (click)="myClick($event)"> -->
    <!-- login as guestuser/guest -->
  <!-- </button> -->
  <!-- </div> -->
<!-- </div> -->
</adf-login>
<!-- [fieldsValidation]="customValidation"
#alfrescoLogin -->

<!-- <adf-login
  [copyrightText]="'application.copyright' | adfAppConfig | translate"
  providers="ECM"
  successRoute="/personal-files"
  logoImageUrl="./assets/images/alfresco-logo.svg"
  backgroundImageUrl="./assets/images/Wallpaper-BG-generic.svg"
  [showRememberMe]="false"
  [showLoginActions]="false"
>
</adf-login> -->
