<div class="search-file-name">
  <span
    tabindex="0"
    role="link"
    *ngIf="isFile"
    (click)="showPreview($event)"
    (keyup.enter)="showPreview($event)"
    class="aca-link aca-crop-text"
    [title]="nameStripped"
    [innerHTML]="name$ | async"
  ></span>
  <span
    tabindex="0"
    role="link"
    *ngIf="!isFile"
    (click)="navigate($event)"
    (keyup.enter)="navigate($event)"
    class="bold aca-link aca-crop-text"
    [title]="nameStripped"
    [innerHTML]="name$ | async"
  ></span>
  <span
    data-automation-id="search-results-entry-title"
    class="aca-crop-text"
    [title]="titleStripped"
    [innerHTML]="title$ | async"
  ></span>
  <!-- <span
  data-automation-id="search-results-entry-title"
  class="aca-crop-text"
  [title]="summaryStripped"
  [innerHTML]="summary$ | async"
></span> -->

</div>
<div
  data-automation-id="search-results-entry-description"
  class="aca-crop-text"
  [title]="descriptionStripped"
  [innerHTML]="description$ | async"
></div>
<!-- @MOD took out the location in the search result -->
<div class="aca-result-location">
  <!-- <aca-location-link [context]="context" [showLocation]="false"></aca-location-link> -->
  <!-- @TBD put in summary? {{cf:summary}} -->

  <!-- <span>{{ summaryStripped | async }}</span> -->
</div>
<div class="aca-result-content aca-crop-text" [title]="contentStripped" [innerHTML]="content$ | async"></div>
